<template>
    <OrderData/>
</template>
<script>
import OrderData from '../components/orders/OrderData';

export default {
  components: {
    OrderData,
  },
};
</script>
