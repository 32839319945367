<template>
    <div v-if="order" class="container type--compact">
        <div class="card">
            <div class="card-body">
                <div class="row" v-if="isJob">
                    <div class="col-12">
                        <h4 class="mb-3">
                            <strong v-if="order.vessel && order.vessel.name">
                                {{order.vessel.name}}
                            </strong>
                            <span class="text-capitalize">
                                {{orderType}}
                            </span>
                            <span>
                                {{isSale || isTreatment ? 'in' : 'to' }}
                            </span>
                            <strong v-if="order.job_location">
                                <span v-if="order.job_location.city">
                                    {{order.job_location.city}},
                                </span>
                                <span v-if="order.job_location.country
                                && order.job_location.country.name">
                                    {{order.job_location.country.name}}
                                </span>
                            </strong>
                        </h4>
                        <h6 v-if="order.reference_no" class="mb-3">
                            Reference Nr: <strong>{{order.reference_no}}</strong>
                        </h6>
                    </div>
                </div>
                <div class="row" v-if="!isJob">
                    <div class="col-12 mb-2">
                        <h5>
                            <strong>
                                Product Order on Behalf of
                                <span class="text-primary">Petrochem</span>
                                <span class="text-success">Energy S.A.</span>
                            </strong>
                        </h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb-2">
                        <p>
                            <label class="pr-2">Order Ref:</label>
                            {{ order.number }}
                        </p>
                        <p>
                            {{ order.issued_at | date('DD MMMM YYYY') }}
                        </p>
                    </div>
                </div>
                <div v-if="isJob" class="row">
                    <div class="col-12">
                        <h6 class="mb-5">
                            Client:
                            <strong v-if="order.client_company
                            && order.client_company.name">
                                {{order.client_company.name}}
                            </strong>
                            -
                            <strong v-if="order.client_contact_user
                            && order.client_contact_user.name">
                                {{order.client_contact_user.name}}
                            </strong>
                        </h6>
                    </div>
                </div>
                <div class="row" v-if="!isJob">
                    <div class="col-12">
                        <h6 class="mb-2">
                            <strong>Major Parties & Distribution List</strong>
                        </h6>
                        <p class="mb-3">
                            The following parties are involved in
                            this order and have received a copy:
                        </p>
                        <ol>
                            <li class="mb-4" v-if="order.supplier_company">
                                <h6><strong>Supplier</strong></h6>
                                <div v-if="order.supplier_contact_user">
                                    <p>Contact: {{ order.supplier_contact_user.name }}</p>
                                    <p>
                                        Mobile: {{ order.supplier_contact_user.phone_mobile }}
                                    </p>
                                    <p>
                                        Office: {{ order.supplier_contact_user.phone_office }}
                                    </p>
                                    <p>
                                        Email: {{ order.supplier_contact_user.email }}
                                    </p>
                                </div>
                                <p>
                                    <strong><em>{{ order.supplier_company.name }}</em></strong>
                                </p>
                                <div v-if="order.supplier_company.location">
                                    <p>{{ order.supplier_company.location.address }}</p>
                                    <p>
                                        {{ order.supplier_company.location.postal_code }}
                                        {{ order.supplier_company.location.city }}
                                        <span v-if="order.supplier_company.location.country">
                                            - {{ order.supplier_company.location.country.name }}
                                        </span>
                                    </p>
                                </div>
                            </li>
                            <li class="mb-4" v-if="order.supplier_company">
                                <h6><strong>Loading Location</strong></h6>
                                <p>
                                    <strong><em>{{ order.supplier_company.name }}</em></strong>
                                </p>
                                <div v-if="order.supplier_company.location">
                                    <p>{{ order.supplier_company.location.address }}</p>
                                    <p>
                                        {{ order.supplier_company.location.postal_code }}
                                        {{ order.supplier_company.location.city }}
                                        <span v-if="order.supplier_company.location.country">
                                            - {{ order.supplier_company.location.country.name }}
                                        </span>
                                    </p>
                                </div>
                            </li>
                            <li class="mb-4" v-if="order.logistics_company">
                                <h6><strong>Transport/Customs/Logistics</strong></h6>
                                <div v-if="order.logistics_contact_user">
                                    <p>Contact: {{ order.logistics_contact_user.name }}</p>
                                    <p>
                                        Mobile: {{ order.logistics_contact_user.phone_mobile }}
                                    </p>
                                    <p>
                                        Office: {{ order.logistics_contact_user.phone_office }}
                                    </p>
                                    <p>
                                        Email: {{ order.logistics_contact_user.email }}
                                    </p>
                                </div>
                                <p>
                                    <strong><em>{{ order.logistics_company.name }}</em></strong>
                                </p>
                                <div v-if="order.logistics_company.location">
                                    <p>{{ order.logistics_company.location.address }}</p>
                                    <p>
                                        {{ order.logistics_company.location.postal_code }}
                                        {{ order.logistics_company.location.city }}
                                        <span v-if="order.logistics_company.location.country">
                                            - {{ order.logistics_company.location.country.name }}
                                        </span>
                                    </p>
                                </div>
                            </li>
                            <li class="mb-4">
                                <h6><strong>Order Summary</strong></h6>
                                <p>
                                    We confirm our requirement to load below
                                    mentioned products in their respective quantities.
                                </p>
                                <p>
                                    As usual all parties are to issue and send respective
                                    invoices to Petrochem Energy, Zug.
                                </p>
                                <p>(Please send copy by email and original by mail.)</p>
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="row" v-if="isTreatment">
                    <p class="col-12 text-muted mb-3"><strong>Job</strong></p>
                </div>
                <div class="row" v-if="isDelivery">
                    <p class="col-12 text-muted mb-3"><strong>Delivery</strong></p>
                </div>
                <div class="row" v-if="isTreatment || isDelivery">
                    <div class="col-12">
                        <p v-if="order.job_cargo_size_mtvac" class="mb-3">
                            <label class="pr-2">Cargo size</label>
                            {{ order.job_cargo_size_mtvac }} mt/vac
                        </p>
                        <p v-if="order.job_treatment_at">
                            <label class="pr-2">Treatment at</label>
                            {{ order.job_treatment_at | date }}
                        </p>
                        <p v-if="order.job_additivation_started_at">
                            <label class="pr-2">Additivation started at</label>
                            {{ order.job_additivation_started_at | date }}
                        </p>
                        <p v-if="order.job_additivation_ended_at" class="mb-3">
                            <label class="pr-2">Additivation ended at</label>
                            {{ order.job_additivation_ended_at | date }}
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="text-muted mb-2"><strong>Items</strong></p>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-hover">
                        <thead>
                        <tr>
                            <th>Product</th>
                            <th>Quantity</th>
                            <th>
                                    <span v-if="!isJob">
                                        Price
                                    </span>
                            </th>
                            <th class="text-right">
                                    <span v-if="!isJob">
                                        Subtotal
                                    </span>
                            </th>
                            <th class="text-right">Status</th>
                        </tr>
                        </thead>
                        <tbody v-for="(line, index) in orderLines"
                               :key="line.id">
                        <tr class="pointer bg-highlight"
                            v-if="!( line.product ? line.product.is_hidden : false )"
                            @click="editOrderLine({ line: line, index: index })">
                            <td>
                                <strong>
                                    {{line.product ? line.product.name : '-'}}
                                </strong>
                            </td>
                            <td>{{ line.quantity }} {{ line.unit_type_code || '' }}</td>
                            <td>
                                    <span v-if="!isJob">
                                        {{ line.price | currency(2,  order.currency_code) }}
                                    </span>
                            </td>
                            <td class="text-right">
                                    <span v-if="!isJob">
                                        {{ (line.price * line.quantity)
                                        | currency(2, order.currency_code) }}
                                    </span>
                            </td>
                            <td class="text-right">
                                <strong>
                                    {{ line.status.name }}
                                </strong>
                            </td>
                        </tr>
                        <tr class="bg-light">
                            <td colspan="5">
                                <p v-if="line.dispatching_warehouse">
                                    <label>Dispatching warehouse:</label>
                                    {{ line.dispatching_warehouse.name }}
                                </p>
                                <div v-if="line.receiving_warehouse">
                                    <h6>Delivery address</h6>
                                    <div class="mb-2" v-if="line.receiving_warehouse.company">
                                        License number <strong>delivery</strong> address:
                                        {{ line.receiving_warehouse.license_no }}<br>
                                        <strong>
                                            {{ line.receiving_warehouse.company.name }}<br>
                                        </strong>
                                        {{ line.receiving_warehouse.location.address }}<br>
                                        {{ line.receiving_warehouse.location.postal_code }}
                                        {{ line.receiving_warehouse.location.city }}<br>
                                    </div>
                                    <div v-if="line.receiving_warehouse.company">
                                        License number
                                        {{ line.receiving_warehouse.keeper_no }}<br>
                                        {{ line.receiving_warehouse.company.name }}<br>
                                        <div v-if="recWarehouseCompanyLocation(line)">
                                            {{ recWarehouseCompanyLocation(line).address }}<br>
                                            {{ recWarehouseCompanyLocation(line).postal_code }}
                                            {{ recWarehouseCompanyLocation(line).city }}<br>
                                        </div>
                                    </div>
                                </div>
                                <p v-if="line.job_treatment_category">
                                    <label>Treatment category:</label>
                                    {{ line.job_treatment_category.name }}
                                </p>
                                <p>
                                        <span v-if="line.job_density">
                                            <label>PPM</label>
                                            {{ line.job_density }},
                                        </span>
                                    <span v-if="line.job_spec_before">
                                            <label>Blank</label>
                                            {{ line.job_spec_before }},
                                        </span>
                                    <span v-if="line.job_spec_required">
                                            <label>Spec required</label>
                                            {{ line.job_spec_required }},
                                        </span>
                                    <span v-if="line.job_spec_after">
                                            <label>Result</label>
                                            {{ line.job_spec_after }}
                                        </span>
                                </p>
                                <p v-if="line.package">
                                    <label>Package:</label>
                                    {{ line.package.name }}
                                </p>
                                <p v-if="line.package_quantity">
                                    <label>Package quantity:</label>
                                    {{ line.package_quantity }}
                                </p>
                            </td>
                        </tr>
                        </tbody>
                        <tfoot v-if="!isJob">
                        <tr>
                            <td colspan="4" class="font-weight-bold text-right">
                                Total amount:
                                {{totalAmount | currency(2, order.currency_code) }}
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="4" class="font-weight-bold text-right">
                                VAT ({{ vatPct }}%):
                                {{ vatAmount | currency(2, order.currency_code) }}
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="4" class="font-weight-bold text-right">
                                Total with VAT:
                                {{ totalAmountWithVat | currency(2, order.currency_code) }}
                            </td>
                            <td></td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
                <div class="row" v-if="!isJob">
                    <div class="col-12">
                        <p class="mb-2">Please send EAD copy once available.</p>
                        <p>Please can all parties liaise with one
                            another to make the best use of time.</p>
                        <p class="mb-2">Please also send by e-mail a copy of all documents
                            to the following addresses as soon as possible after issue:</p>
                        <p>
                            <a href="mailto:ops@petrochemenergy.com">ops@petrochemenergy.com</a>
                        </p>
                    </div>
                </div>
                <div class="row" v-if="order.notes && isJob">
                    <div class="col-12">
                        <p>
                            <label class="pr-2"><strong>Note</strong></label>
                            <span v-html="order.notes"></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { formatDate } from '../../filters/date.filter';
import formatCurrency from '../../filters/currency.filter';

export default {
  data() {
    return {
      loading: false,
    };
  },
  filters: {
    date: formatDate,
    currency: formatCurrency,
  },
  computed: {
    ...mapGetters({
      order: 'orders/order',
      orderStatuses: 'orders/orderStatuses',
      totalAmount: 'orders/totalAmount',
      vatPct: 'orders/vatPct',
      vatAmount: 'orders/vatAmount',
      totalAmountWithVat: 'orders/totalAmountWithVat',
      orderLines: 'orders/orderLines',
      currencies: 'currencyRates/currencies',
    }),
    isNew() {
      return this.$route.params.id === 'new';
    },
    isJob() {
      return this.isDelivery || this.isTreatment || this.isSale;
    },
    orderType() {
      return this.$route.params.orderType;
    },
    isPurchase() {
      return this.orderType === 'purchase';
    },
    isTransfer() {
      return this.orderType === 'transfer';
    },
    isTreatment() {
      return this.orderType === 'treatment';
    },
    isDelivery() {
      return this.orderType === 'delivery';
    },
    isSale() {
      return this.orderType === 'sale';
    },
  },
  watch: {
    '$route.params.id'() {
      this.getOrder();
    },
  },
  mounted() {
    this.getCurrencies();
    this.getOrder();
  },
  methods: {
    recWarehouseCompanyLocation(line) {
      return line.receiving_warehouse.company.location;
    },
    getCurrencies() {
      this.$store.dispatch('currencyRates/getCurrencies');
    },
    getOrder() {
      this.loading = true;
      this.$store.dispatch('orders/getOrder', this.$route.params.id)
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
